<template>
  <div>
    <dx-data-grid
      id="batchShippingItemGrid"
      ref="batchShippingItemRef"
      :data-source="dataSource"
      height="180"
      :cache-eyarnabled="false"
      :allow-column-reordering="false"
      :allow-column-resizing="true"
      :column-auto-width="false"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :row-alternation-enabled="false"
      :hover-state-enabled="true"
      :customize-columns="customizeColumns"
      @initialized="onInitialized"
    >
      <dx-column data-field="batchName" />
      <dx-column data-field="destCode" caption="Dest" width="80" />
      <dx-column data-field="cityState" caption="Dest City" />
      <dx-column :visible="canConsolidate" data-field="accountNo" caption="Consolidate" cell-template="consolidateTemplate" alignment="center" />
      <template #consolidateTemplate="{ data }">
        <div>
          <dx-util-button
            v-if="data.data.status !== statusEnum.CONSOLIDATED.key && data.data.status !== statusEnum.CONSOLIDATING.key"
            :disabled="isConsolidating"
            text="Consolidate"
            type="default"
            styling-mode="contained"
            :element-attr="btnElementAttr"
            @click="onConsolidateClick(data)"
          />
          <div v-if="isConsolidated && data.data.status === statusEnum.CONSOLIDATED.key" class="d-flex justify-content-between align-items-center pb-0">
            <dx-util-button
              v-if="isConsolidated && data.data.status === statusEnum.CONSOLIDATED.key"
              text="Create"
              type="warning"
              styling-mode="contained"
              @click="onClickCreateConsolidate(data)"
            />
            <dx-util-button
              v-if="isConsolidated && data.data.status === statusEnum.CONSOLIDATED.key"
              text="Delete"
              type="danger"
              @click="onClickCancelCreateConsolidate(data, 'Delete')"
            />
          </div>
          <div v-if="isConsolidating && data.data.status === statusEnum.CONSOLIDATING.key" class="d-flex justify-content-between align-items-center pb-0">
            <dx-util-load-indicator
              :indicator-src="`${require('@/assets/images/svg/bars.svg')}`"
            />
            <dx-util-button
              text="Stop"
              type="danger"
              @click="onClickCancelCreateConsolidate(data, 'Stop')"
            />
          </div>
        </div>
      </template>
      <dx-column :visible="showSuccessRate" data-field="notes" caption="Success %" cell-template="showSuccessRateTemplate" alignment="center" />
      <template #showSuccessRateTemplate="{data}">
        <div>
          <span v-if="data.value" class="badge badge-light-success"> {{ data.value }} </span>
        </div>
      </template>
      <dx-column data-field="shipmentId" />
      <dx-column data-field="creationTime" caption="Created At" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
      <!-- <dx-column data-field="prepType" /> -->
      <dx-column data-field="skuCount" caption="SKU QTY" alignment="right" width="90" />
      <dx-column data-field="itemCount" caption="Item QTY" alignment="right" width="90" />
      <dx-column data-field="addedQuantity" caption="Boxed" cell-template="addedQtyTemplate" alignment="center" width="90" />
      <template #addedQtyTemplate="{ data }">
        <div>
          <span v-if="isBoxingDoneForBatch(data)" v-b-tooltip.d800.hover.left.v-success="'Ready to Transmit.'" class="d-block badge badge-success">
            {{ data.value }}
          </span>
          <span v-else v-b-tooltip.d800.hover.left.v-warning="'More items to add!'" class="d-block badge badge-light-warning">
            {{ data.value }}
          </span>
        </div>
      </template>
      <dx-column data-field="remainingQuantity" caption="Remaining" alignment="right" width="100" />
      <dx-column data-field="status" caption="PSH Status" cell-template="statusCellTemplate" width="120" />
      <template #statusCellTemplate="{data}">
        <div>
          {{ renderShipmentStatus(data.value).text }}
        </div>
      </template>
      <dx-column data-field="amzStatus" caption="AMZ Status" cell-template="amzStatusCellTemplate" />
      <template #amzStatusCellTemplate="{data}">
        <div>
          {{ renderAmzStatus(data.value).text }}
        </div>
      </template>
      <dx-column data-field="transportStatus" cell-template="transportStatusCellTemplate" />
      <template #transportStatusCellTemplate="{data}">
        <div>
          {{ renderTransporStatus(data.value).text }}
        </div>
      </template>
      <dx-column data-field="id" caption="Boxing" alignment="center" cell-template="itemActions" width="90" />
      <template #itemActions="{ data }">
        <dx-util-button v-if="data.data.status === statusEnum.IN_PREP.key" type="default" icon="box" @click="onClickBatchBoxing(data.row.data)" />
      </template>
      <dx-column :visible="!isConsolidating" cell-template="buttonsCellTemplate" caption="Actions" :width="190" type="buttons" />
      <template #buttonsCellTemplate="{data}">
        <dx-util-drop-down-button
          v-if="data.data.status !== statusEnum.CONSOLIDATED.key"
          :id="getShippingActionName(data).id"
          ref="dropDownShippingItemActionsRef"
          :split-button="false"
          :use-select-mode="false"
          :items="shippingItemActions"
          :drop-down-options="{ width: 170 }"
          styling-mode="contained"
          :show-arrow-icon="false"
          :text="getShippingActionName(data).name"
          :icon="getShippingActionName(data).icon"
          width="170"
          display-expr="name"
          key-expr="id"
          item-template="item-temp"
        >
          <template #item-temp="{data: item}">
            <dx-util-button
              :id="item.btnId"
              :text="item.name"
              :icon="item.icon"
              :disabled="isDisabledAction(data, item.key)"
              :visible="item.visible"
              :class="item.class"
              :element-attr="item.elementAttr"
              width="170"
              @click="onShippingItemActionsClick($event, data, item.id)"
            />
          </template>
        </dx-util-drop-down-button>
      </template>
    </dx-data-grid>
    <shipping-item-content-bulk-box
      :component-id="bulkBoxComponentProps.componentId"
      :batch-id="bulkBoxComponentProps.shipmentBatchId"
      :shipment-batch-name="bulkBoxComponentProps.shipmentBatchName"
      :shipment-batch-destination="bulkBoxComponentProps.shipmentBatchDestCode"
      @on-emit-bulk-box-closed-popup="onEmitBulkBoxClosedPopup"
    />
    <!--BEGIN::Consolidate Create-->
    <dx-util-popup
      ref="popupCreateConsolidateRef"
      title="Create Consolidate Shipment"
      :width="1000"
      :height="800"
      :show-close-button="true"
      :show-title="true"
      :drag-enabled="false"
      :close-on-click-outside="true"
      :close-on-escape="true"
      :scrolling-enabled="true"
      content-template="popup-content"
      @shown="onShownPopupCreateConsolidate"
      @hiding="onHidingPopupCreateConsolidate"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
      <template #cancelPopupButton>
        <dx-util-button id="popupCancelButtonCreateConsolidate" text="Cancel" type="danger" @click="onClickCancelButtonCreateConsolidate" />
      </template>
      <dx-util-toolbar-item toolbar="bottom" location="before" template="submitPopupButton" />
      <template #submitPopupButton>
        <dx-util-button id="popupSubmitButtonCreateConsolidate" text="Create" type="success" @click="onClickSubmitButtonCreateConsolidate" />
      </template>
      <template #popup-content>
        <div>
          <div class="row">
            <div class="col-xs-12 col-sm-12" style=" padding: 5px 10px; background-color: rgba(223, 230, 233, 0.8); color: #000;">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
                <span>
                  <strong>Beta Version: This is a beta version of this feature. It is currently being tested and its usage is for free. It may be limited upon the full version release and will be subject to additional charges.</strong>
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 mb-1">
              <div class="card border mb-0">
                <div class="card-body py-2">
                  <div class="d-flex flex-row align-items-center">
                    <div class="text-warning font-weight-bold">
                      Are you sure you want to create this shipment? This action cannot be reversed.
                    </div>
                    <div class="mr-half">
                      <p-icon name="bi-exclamation-circle-fill text-warning" size="32px" />
                    </div>
                    <div class="text-warning font-weight-bold">
                      Some items may not qualify for consolidation. Please select an option for leftover items!
                    </div>
                </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 mb-1">
              <dx-util-radio-group
                v-model="consolidateFormData.assignToExistingPlan"
                :data-source="assignToExistingPlanRadioOptions"
                display-expr="text"
                value-expr="value"
                layout="horizontal"
                @value-changed="onAssignToExistingPlanChanged"
              />
            </div>
            <div v-if="consolidateFormData.assignToExistingPlan" class="col-xs-12 col-sm-12">
              <div class="card border">
                <div class="card-header border-bottom py-1">
                  <span class="fs-h-5">
                    Select Existing Shipment Plan
                  </span>
                </div>
                <div class="card-body py-2">
                  <dx-util-radio-group v-if="shipmentPlanList.length > 0" v-model="consolidateFormData.shipmentPlanId" :data-source="shipmentPlanList" display-expr="shipmentPlanName" value-expr="id" layout="vertical" />
                  <div v-else class="text-warning font-weight-bold">
                    No shipment plans available. Create a new shipment plan.
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="consolidateFormData.assignToExistingPlan === false" class="col-xs-12 col-sm-12">
              <div class="card border">
                <div class="card-header border-bottom py-1">
                  <span class="fs-h-5">
                    Create New Shipment Plan
                  </span>
                </div>
                <div class="card-body py-2">
                  <dx-util-text-box v-model="consolidateFormData.name" placeholder="Shipment Plan Name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dx-util-popup>
    <!--END::Consolidate Create-->
    <!--Begin:: Create Or Update Form-->
    <dx-util-popup
      ref="updateFromAddressPopupRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :width="640"
      :height="360"
      title="Update From Address"
      @hidden="onHiddenUpdateFromAddressPopup"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveUpdateFromAddressPopupButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelUpdateFromAddressPopupButtonOptions" />
      <form @submit="handleSubmitUpdateFromAddress">
        <dx-util-form ref="updateFromAddressPopupFromRef" :form-data="updateFromAddressData" :col-count="1" :show-colon-after-label="true" label-location="top">
          <template #selected-address-template="{}">
            <div class="d-flex justify-content-start">
              <div v-html="selectedShipFromAddress" />
            </div>
          </template>
          <dx-util-simple-item>
            <template #default>
              <dx-util-select-box
                v-model="updateFromAddressData.shipFrom"
                :data-source="shippingAddress"
                display-expr="text"
                value-expr="id"
                item-template="item"
                @value-changed="onFromAddressValueChanged"
              >
                <template #item="{ data }">
                  <div v-html="data.textHtml" />
                </template>
              </dx-util-select-box>
            </template>
           </dx-util-simple-item>
          <dx-util-simple-item template="selected-address-template" />
          </dx-util-form>
        </form>
      </dx-util-popup>
    <!--End:: Create Or Update Form-->
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

import { emitter } from '@/libs/bus'
import store from '@/store'
import { v4 as uuidv4 } from 'uuid'
import { FBAEvents } from '@/libs/constants/events.const'
import isNaN from 'lodash/isNaN'
import find from 'lodash/find'
import moment from 'moment'
import { Notify } from '@robustshell/utils/index'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import commonService from '@/http/requests/common/commonEntityService'
import shipmentStatusEnum, { getShipmentStatusEnum } from '@/enums/shipmentStatusEnum'
import { getAmzShipmentStatusEnum } from '@/enums/amzShipmentStatusEnum'
import { getTransportStatusEnum } from '@/enums/transportStatusEnum'
import { awaiter } from '@/libs/utils'
import useCurrentUser from '@/libs/app/current-user'
import Pager from '@core/dev-extreme/utils/pager'
import SearchFilter from '@/http/models/search-filters/FbaShipmentSearchFilter'
import useConfirmation from '@/libs/app/confirmation'
import ShippingItemContentBulkBox from './ShippingItemContentBulkBox.vue'

export default {
  components: {
    ShippingItemContentBulkBox,
  },
  props: {
    batchId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { pshConfirm } = useConfirmation()
    const { userFullname, userTenantId, isServingTenant } = useCurrentUser()
    return {
      pshConfirm,
      userTenantId,
      userFullname,
      isServingTenant,
    }
  },
  data() {
    return {
      dataSource: [],
      shippingItemBulkBoxComponentId: '',
      shippingItemBatchId: 0,
      bulkBoxComponentProps: {
        componentId: '',
        shipmentBatchId: 0,
        shipmentBatchName: '',
        shipmentBatchDestCode: '',
      },
      btnElementAttr: {
        class: 'btn-block',
      },
      showSuccessRate: false,
      canConsolidate: false,
      isConsolidating: false,
      isConsolidated: false,
      consolidateFormData: {
        shipmentPlanId: 0,
        consolidatedBatchId: 0,
        assignToExistingPlan: null,
        name: '',
      },
      shipmentPlanList: [],
      assignToExistingPlanRadioOptions: [
        { value: true, text: 'Select Existing Shipment Plan' },
        { value: false, text: 'Create New Shipment Plan' },
      ],
      statusEnum: shipmentStatusEnum,
      loadDataIntervalId: null,
      loadDataLastInteractionTime: null,
      updateFromAddressData: {
        batchId: 0,
        shipFrom: null,
      },
      shippingAddress: [],
      selectedShipFromAddress: null,
    }
  },
  computed: {
    accountInfo() {
      return store.getters['fbaShipment/getBatchAccountInfo']
    },
    shippingItemActions() {
      return [
        {
          id: 'create',
          btnId: 'btn-create',
          key: [shipmentStatusEnum.NOT_CREATED_YET.key],
          visible: true,
          disabled: this.isConsolidating,
          name: 'Create',
          icon: 'check',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'transmit',
          btnId: 'btn-transmit',
          key: [shipmentStatusEnum.IN_PREP.key],
          visible: true,
          disabled: this.isConsolidating,
          name: 'Transmit',
          icon: 'mediumiconslayout',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'shipping',
          btnId: 'btn-shipping',
          key: [shipmentStatusEnum.READY_TO_SHIP.key, shipmentStatusEnum.PRE_SHIP.key, shipmentStatusEnum.SHIPPED.key],
          visible: true,
          disabled: this.isConsolidating,
          name: 'Go to Shipping',
          icon: 'box',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'shipped',
          btnId: 'btn-shipped',
          key: '',
          visible: false,
          disabled: this.isConsolidating,
          name: 'Shipped',
          icon: 'tags',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'fromAddressUpdate',
          btnId: 'btn-create',
          key: '',
          visible: true,
          name: 'Update Address',
          icon: 'map',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
      ]
    },
    dataGridIns() {
      return this.$refs.batchShippingItemRef.instance
    },
    popupInsCreateConsolidate() {
      return this.$refs.popupCreateConsolidateRef.instance
    },
    updateFromAddressPopupIns() {
      return this.$refs.updateFromAddressPopupRef.instance
    },
    saveUpdateFromAddressPopupButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmitUpdateFromAddress(e)
        },
      }
    },
    cancelUpdateFromAddressPopupButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.updateFromAddressPopupIns.hide()
        },
      }
    },
  },
  watch: {
    isConsolidated(val) {
      if (val) {
        this.clearReloadInterval()
      }
    },
  },
  created() {
    emitter.on(FBAEvents.UPDATE_SHIPMENT_BATCHES_AFTER_ADDED_BOX_ITEM.event, () => {
      this.dataSourceLoad()
    })
    // This event listener is for handling visibility change
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
    this.loadDataLastInteractionTime = Date.now()
  },
  mounted() {
    this.dataSourceLoad()
  },
  beforeDestroy() {
    clearInterval(this.loadDataIntervalId)
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  destroyed() {
    emitter.off(FBAEvents.UPDATE_SHIPMENT_BATCHES_AFTER_ADDED_BOX_ITEM.event)
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        const loadElapsedTime = Date.now() - this.loadDataLastInteractionTime
        const loadInterval = 20 * 1000
        if (loadElapsedTime >= loadInterval) {
          this.dataSourceLoad()
        }
      } else {
        this.loadDataLastInteractionTime = Date.now()
      }
    },
    setConsolidateStatus() {
      const data = this.dataSource
      if (data.length > 0) {
        this.isConsolidating = false
        const isExistConsolidating = find(data, p => p.status === shipmentStatusEnum.CONSOLIDATING.key)
        if (isExistConsolidating) {
          this.isConsolidating = true
        }
        this.isConsolidated = false
        const isExistConsolidated = find(data, p => p.status === shipmentStatusEnum.CONSOLIDATED.key)
        if (isExistConsolidated) {
          this.isConsolidated = true
        }
        this.canConsolidate = false
        const canConsolidateTemp = find(data, p => p.status === shipmentStatusEnum.CONSOLIDATED.key || p.status === shipmentStatusEnum.CONSOLIDATING.key || p.status === shipmentStatusEnum.NOT_CREATED_YET.key)
        const canConsolidate = canConsolidateTemp && !find(data, p => p.status !== shipmentStatusEnum.CONSOLIDATED.key && p.status !== shipmentStatusEnum.CONSOLIDATING.key && p.status !== shipmentStatusEnum.NOT_CREATED_YET.key)
        if (canConsolidate && data.length > 1) {
          this.canConsolidate = true
        }
      }
    },
    rowConolidatedStatus(row) {
      const { data } = row
      return data.status === shipmentStatusEnum.CONSOLIDATED.key
    },
    customizeColumns(columns) {
      columns.forEach(column => {
        column.cssClass = 'dx-column-vertical-middle'
      })
    },
    onInitialized(e) {
      e.component.option('loadPanel.enabled', false)
    },
    getShippingActionName(row) {
      const { data } = row
      const rowStatus = getShipmentStatusEnum(data.status)
      const currentItem = this.shippingItemActions.find(p => {
        const keys = [...p.key]
        const exist = keys.find(c => c === rowStatus.key)
        if (exist) {
          return true
        }
        return false
      })
      if (currentItem) {
        return {
          id: currentItem.id,
          name: currentItem.name,
          icon: currentItem.icon,
        }
      }
      return {
        id: 'shipped',
        name: 'Shipped',
        icon: 'tags',
      }
    },
    isDisabledAction(row, key) {
      const { data } = row
      if (key) {
        const rowStatus = getShipmentStatusEnum(data.status)
        return !key.includes(rowStatus.key)
      }
      return false
    },
    renderShipmentStatus(status) {
      return getShipmentStatusEnum(status)
    },
    renderAmzStatus(status) {
      const result = getAmzShipmentStatusEnum(status)
      return result
    },
    renderTransporStatus(status) {
      const result = getTransportStatusEnum(status)
      return result
    },
    isBoxingDoneForBatch(data) {
      return data.data.addedQuantity === data.data.itemCount
    },
    async dataSourceLoad() {
      const planId = parseInt(this.batchId, 10)
      if (isNaN(planId)) {
        return
      }

      this.dataSource.length = 0
      await fbaShipmentService.getBatchDataByParentId(planId).then(response => {
        const data = response.data
        data.forEach(batch => {
          this.showSuccessRate = this.showSuccessRate || batch.notes !== null
        })
        this.dataSource = [...data]
        this.setConsolidateStatus()
      })
    },
    onShippingItemActionsClick(e, row, id) {
      const { data } = row
      if (!id) return
      switch (id) {
        case 'create':
          this.onCreateBatch(data)
          break
        case 'transmit':
          this.onTransmiteBatch(data)
          break
        case 'shipping':
          this.onGotoShipping(data)
          break
        case 'fromAddressUpdate':
          this.onUpdateFromAddress(data)
          break
        default:
          break
      }
    },
    onCreateBatch(data) {
      this.$bvModal
        .msgBoxConfirm('Please confirm Shipment Batch creation. Once you create this batch, it will appear in seller central Shipping Queue in Working state.', {
          title: 'Create Shipment Batch',
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'Create',
          cancelTitle: 'Cancel',
          cancelVariant: 'danger',
          headerClass: 'px-1 py-half border-bottom-0',
          bodyClass: 'px-1 py-half',
          footerClass: 'd-flex flex-row justify-content-between px-1 py-half border-top-0',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            fbaShipmentService
              .createAmazonInboundShipment(data.id)
              .then(result => {
                Notify.success('Shipment Batch has been created successfully')
              })
              .then(() => {
                const payload = {
                  barcodeLabelText: `${data.destCode}-U001`,
                }
                emitter.emit(FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.event, FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.action)
                emitter.emit(FBAEvents.PRINT_CODE_LABEL_CONDITIONAL.event, payload)
              })
              .then(() => {
                this.dataSourceLoad()
              })
          }
        })
    },
    async onTransmiteBatch(data) {
      const result = await fbaShipmentService.getCanTransmitBatch(data.id)
      const payload = {
        batchId: this.batchId,
        shipmentBatchId: data.id,
        shipmentBatchName: data.shipmentId,
        shipmentBatchDestCode: data.destCode,
        shipmentBatchTransportStatus: data.transportStatus,
      }
      this.$emit('emit-batch-transmite', payload)
    },
    async onGotoShipping(data) {
      const payload = {
        batchId: this.batchId,
        shipmentBatchId: data.id,
        shipmentBatchName: data.shipmentId,
        shipmentBatchDestCode: data.destCode,
        shipmentBatchTransportStatus: data.transportStatus,
        shipmentPartnerShipped: data.partnerShipped,
      }
      this.$emit('emit-batch-transmite', payload)
    },
    // #region Update From Address
    getShippingAddress(companyId) {
      this.shippingAddress.splice(0, this.shippingAddress.length)
      return commonService.fetchAddressesByTenantAndCompany(companyId).then(result => {
        const { data } = result
        data.forEach((item, index) => {
          let line = item.line1
          line += item.line2 ? ` ${item.line2}` : ''
          line += item.line3 ? ` ${item.line3}` : ''
          let addressStringHtml = `${item.name}<br />${line}`
          let addressString = `${item.name} ${line}`

          if (this.isServingTenant) {
            addressStringHtml += `<br />${item.city}, ${item.state} ${item.zipcode}`
            addressString += `${item.city}, ${item.state} ${item.zipcode}`
          } else {
            addressStringHtml += `<br />${item.city}, ${item.state} ${item.zipcode}`
            addressString += ` ${item.city}, ${item.state} ${item.zipcode}`
          }
          this.shippingAddress.push({
            id: item.id,
            textHtml: addressStringHtml,
            text: addressString,
          })
        })
      })
    },

    onFromAddressValueChanged(e) {
      const selected = find(this.shippingAddress, p => p.id === e.value)
      this.selectedShipFromAddress = selected.textHtml
    },

    onUpdateFromAddress(data) {
      this.updateFromAddressData.batchId = data.id
      this.updateFromAddressData.shipFrom = data.shipFrom
      this.getShippingAddress(data.companyId).then(() => {
        if (data.shipFrom) {
          const selected = find(this.shippingAddress, p => p.id === data.shipFrom)
          this.selectedShipFromAddress = selected.textHtml
        }
        this.$nextTick(() => {
          this.updateFromAddressPopupIns.show()
        })
      })
    },

    async handleSubmitUpdateFromAddress(e) {
      const data = this.updateFromAddressData
      if (data.shipFrom) {
        await fbaShipmentService.updateShipFromAddress(data.batchId, data.shipFrom)
        Notify.success('From address has been updated successfully')
        this.updateFromAddressPopupIns.hide()
        return
      }
      Notify.warning('Please select a shipping address to proceed.')
      this.updateFromAddressPopupIns.hide()
    },

    onHiddenUpdateFromAddressPopup() {
      this.updateFromAddressData.batchId = 0
      this.updateFromAddressData.shipFrom = null
      this.selectedShipFromAddress = null
    },
    // #endregion
    onClickBatchBoxing(data) {
      this.bulkBoxComponentProps.componentId = uuidv4()
      this.bulkBoxComponentProps.shipmentBatchId = data.id
      this.bulkBoxComponentProps.shipmentBatchName = data.shipmentId
      this.bulkBoxComponentProps.shipmentBatchDestCode = data.destCode
    },
    onEmitBulkBoxClosedPopup() {
      awaiter(500).then(() => {
        emitter.emit(FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.event, FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.action)
        this.$emit('emit-update-shipping-item-content')
      })
    },
    // #region Consolidate
    async getShipmentPlans() {
      const pager = new Pager({ pageSize: 100 })
      const filter = new SearchFilter()
      filter.accountNo = this.accountInfo.accountNo
      filter.companyId = this.accountInfo.companyId
      filter.storeId = this.accountInfo.storeId
      filter.batchStatus = 'open'
      const result = await fbaShipmentService.getBatchParentsByQuery(filter, pager.toCreatePageable())
      const data = result.data.body.content
      this.shipmentPlanList = []
      this.shipmentPlanList = data.map(batch => ({
        id: batch.id,
        shipmentPlanName: batch.batchName,
      }))
    },
    onAssignToExistingPlanChanged(e) {
      if (e.value === true || e.value === null) {
        this.consolidateFormData.shipmentPlanId = 0
        this.consolidateFormData.name = ''
      } else {
        const now = new Date()
        const formatedDate = moment(now).format('YYYY-MM-DD HH:mm:ss')
        const userName = this.userFullname.split(' ')[0].charAt(0) + this.userFullname.split(' ')[1].charAt(0)
        let batchName = `${this.userTenantId}-${userName} ${formatedDate}`
        if (!this.isServingTenant) {
          batchName = `${userName} ${formatedDate}`
        }
        this.consolidateFormData.name = batchName
      }
    },
    async onConsolidateClick(row) {
      const { data } = row
      const dest = data.destCode
      const confirm = await this.pshConfirm(
        'Confirm Batch Consolidation',
        'Please confirm that you want to consolidate shipment batch.',
      )
      if (confirm) {
        try {
          await fbaShipmentService.consolidateAmazonInboundShipmentPlan(this.batchId, dest)
          this.isConsolidating = true
          this.dataSourceLoad()

          const loadIntervalTime = 20 * 1000 // 20 seconds
          this.loadDataIntervalId = setInterval(() => {
            this.dataSourceLoad()
          }, loadIntervalTime)
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    clearReloadInterval() {
      clearInterval(this.loadDataIntervalId)
      this.dataSourceLoad()
    },
    async onClickCancelCreateConsolidate(row, action) {
      const { data } = row
      this.$swal({
        title: `${action} Shipment Consolidation`,
        text: `Please confirm that you want to ${action} shipment plan consolidation.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${action} Consolidation`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          fbaShipmentService.cancelConsolidatingAmazonInboundShipment(data.id)
            .then(result1 => {
              this.isConsolidating = false
              this.canConsolidate = true
              this.showSuccessRate = false
              Notify.success('Shipment plan consolidation has been cancelled')
            })
            .then(() => {
              this.dataSourceLoad()
            })
        }
      })
    },
    onClickCreateConsolidate(row) {
      const { data } = row
      this.consolidateFormData.consolidatedBatchId = data.id
      this.popupInsCreateConsolidate.show()
    },
    async onShownPopupCreateConsolidate() {
      await this.getShipmentPlans()
    },
    onHidingPopupCreateConsolidate() {
      this.shipmentPlanList = []
      this.consolidateFormData = {
        shipmentPlanId: 0,
        consolidatedBatchId: 0,
        assignToExistingPlan: null,
        name: '',
      }
    },
    onClickCancelButtonCreateConsolidate() {
      this.popupInsCreateConsolidate.hide()
    },
    async onClickSubmitButtonCreateConsolidate() {
      const data = this.consolidateFormData
      if (data.assignToExistingPlan === true) {
        if (data.shipmentPlanId === 0) {
          Notify.warning('Please select a shipment plan to proceed.')
          return
        }
      } else if (data.assignToExistingPlan === false) {
        if (data.name === '') {
          Notify.warning('Please enter a shipment plan name to proceed.')
          return
        }
      } else {
        Notify.warning('Please select an option to proceed.')
        return
      }
      await fbaShipmentService.createConsolidatedAmazonInboundShipment(data)
        .finally(() => {
          this.popupInsCreateConsolidate.hide()
          this.canConsolidate = false
          this.showSuccessRate = false
          this.popupInsCreateConsolidate.hide()
          this.dataSourceLoad()
          this.$emit('emit-create-consolidation')
        })
    },
    // #endregion
  },
}
</script>

<style lang="scss">
#create {
  .dx-icon {
    font-size: 1.143rem;
    color: #fff;
    margin-right: 0.571rem;
  }
  .dx-button-content {
    text-align: left;
    background-color: #27ae60;
  }
  .dx-buttongroup-item {
    padding-left: 0px;
    padding-right: 1px;
  }
  .dx-dropdownbutton-action:not(.dx-buttongroup-last-item).dx-button {
    border-right-width: 0;
  }
}
#transmit {
  .dx-icon {
    font-size: 1.143rem;
    color: #fff;
    margin-right: 0.571rem;
  }
  .dx-button-content {
    text-align: left;
    background-color: #2980b9;
  }
  .dx-buttongroup-item {
    padding-left: 0px;
    padding-right: 1px;
  }
  .dx-dropdownbutton-action:not(.dx-buttongroup-last-item).dx-button {
    border-right-width: 0;
  }
}
#shipping {
  .dx-icon {
    font-size: 1.143rem;
    color: #fff;
    margin-right: 0.571rem;
  }
  .dx-button-content {
    text-align: left;
    background-color: #ff9f43;
  }
  .dx-buttongroup-item {
    padding-left: 0px;
    padding-right: 1px;
  }
  .dx-dropdownbutton-action:not(.dx-buttongroup-last-item).dx-button {
    border-right-width: 0;
  }
}
#shipped {
  .dx-icon {
    font-size: 1.143rem;
    color: #fff;
    margin-right: 0.571rem;
  }
  .dx-button-content {
    text-align: left;
    background-color: #82868b;
  }
  .dx-buttongroup-item {
    padding-left: 0px;
    padding-right: 1px;
  }
  .dx-dropdownbutton-action:not(.dx-buttongroup-last-item).dx-button {
    border-right-width: 0;
  }
}
</style>
