<template>
  <dx-util-popup
  ref="itemPopupRef"
  :show-close-button="true"
  :drag-enabled="true"
  :close-on-outside-click="false"
  :show-title="true"
  width="90vw"
  height="90vh"
  :title="itemPopupTitle"
  content-template="popup-content"
  @shown="onShownItemPopup"
  @hidden="onHiddenPopup"
  >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
    <template #popup-content>
      <dx-util-scroll-view width="100%" height="100%">
        <dx-data-grid
          id="shippingItemsContentGrid"
          ref="shippingItemsContentRef"
          :data-source="dataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="false"
          :customize-columns="customizeColumns"
          @content-ready="onContentReady"
        >
          <dx-paging :enabled="false" />
          <dx-column data-field="catalogItem" cell-template="item-cell-template" />
          <template #item-cell-template="{ data }">
            <item-info
              :id="data.row.data.id"
              :catalog-item="data.value"
              :title="data.row.data.title"
              :price-list="data.row.data.priceList"
              :pallet-id="data.row.data.palletId"
              :asin="data.row.data.asin"
              :rank="data.row.data.amzRank"
              :upc="data.row.data.upc"
              :msku="data.row.data.msku"
              :fnsku="data.row.data.fnsku"
              :expiration-date="data.row.data.expirationDate"
              :image-url="data.row.data.smallImage"
              :large-image-url="data.row.data.largeImage"
              :product-type="data.row.data.productType"
              :is-printable="false"
              :is-truncated="true"
            />
          </template>
          <dx-editing mode="cell" :allow-updating="true" />
          <dx-column data-field="itemCondition" caption="Condition" :allow-editing="false" />
          <dx-column data-field="quantity" caption="Item Quantity" alignment="center" cell-template="quantity-cell-template" :allow-editing="false" />
          <template #quantity-cell-template="{ data }">
              <div class="d-flex flex-row justify-content-center">
                <div v-b-tooltip.hover="{ variant: 'info' }" title="Quantity" class="align-self-center bg-success rounded px-half badge-item" style="width: 32px;">
                  {{ data.value }}
                </div>
              </div>
          </template>
          <dx-column data-field="itemQuantity" caption="Item Quantity/Box" data-type="number" alignment="left" :width="160" :editor-options="itemQuantityEditorOptions">
            <dx-util-async-rule :validation-callback="itemQuantityValidation" message="Total quantity could not greater than item quantity" />
          </dx-column>
          <dx-column data-field="boxCount" data-type="number" alignment="left" :width="160" :editor-options="boxCountEditorOptions">
            <dx-util-async-rule :validation-callback="boxCountValidation" message="Total quantity could not greater than item quantity" />
          </dx-column>
        </dx-data-grid>
      </dx-util-scroll-view>
    </template>
  </dx-util-popup>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import * as R from 'ramda'
import { Notify } from '@robustshell/utils/index'
import isNaN from 'lodash/isNaN'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import ItemInfo from './ItemInfo.vue'
import fbaShipment from '../fbaShipment.mixin'

export default {
  components: {
    ItemInfo,
  },
  mixins: [GridBase, fbaShipment],
  props: {
    batchId: {
      type: Number,
      required: true,
    },
    shipmentBatchName: {
      type: String,
      required: false,
      default: '',
    },
    shipmentBatchDestination: {
      type: String,
      required: false,
      default: '',
    },
    componentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      batchItems: [],
      queryShipmentBatch: '',
      itemPopupTitle: '',
      dataSource: [],
    }
  },
  computed: {
    itemPopup() {
      const popupIns = this.$refs.itemPopupRef.instance
      return popupIns
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.onSaveBox()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.itemPopupRef.instance
          popup.hide()
        },
      }
    },
    itemQuantityEditorOptions() {
      return {
        showSpinButtons: false,
        min: 0,
        mode: 'number',
        inputAttr: { class: 'form-control' },
        onFocusIn: e => {
          e.event.target.value = null
        },
      }
    },
    boxCountEditorOptions() {
      return {
        showSpinButtons: false,
        min: 0,
        mode: 'number',
        inputAttr: { class: 'form-control' },
        onFocusIn: e => {
          e.event.target.value = null
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current, previous) {
        if (current && current.trim().length > 0) {
          this.createPopup()
        }
      },
    },
  },
  methods: {
    async createPopup() {
      const parsed = parseInt(this.batchId, 10)
      if (isNaN(parsed)) { return }
      const filters = {
        companyId: 0,
        accountNo: '',
        q: '',
        storeId: 0,
        warehouseId: 0,
        beginDate: null,
        endDate: null,
        tenantType: 'SERVING',
        batchId: parsed,
        status: 'ALL',
        batchStatus: 'all',
      }
      const result = await fbaShipmentService.getBatchParentItemsByQuery(filters)

      this.dataSource.length = 0
      result.data.forEach(item => {
        if (!R.isEmpty(item.itemBoxMap)
            && !R.isEmpty(item.itemBoxMap.itemBatchList)
            && R.isEmpty(item.itemBoxMap.itemBatchList[0].boxItemQuantityList)
        ) {
          const batchItem = {
            asin: item.asin,
            upc: item.upc,
            fnsku: item.fnsku,
            msku: item.msku,
            batchParentId: item.batchParentId,
            catalogItem: item.catalogItem,
            priceList: item.priceList,
            companyId: item.companyId,
            countInBundle: item.countInBundle,
            countPerBundle: item.countPerBundle,
            damagedCount: item.damagedCount,
            expirationDate: item.expirationDate,
            id: item.id,
            itemAction: item.itemAction,
            itemCondition: item.itemCondition,
            itemCost: item.itemCost,
            itemSource: item.itemSource,
            notes: item.notes,
            orderNumber: item.orderNumber,
            packType: item.packType,
            planId: item.planId,
            productId: item.productId,
            quantity: item.quantity,
            receivedCount: item.receivedCount,
            reservedCount: item.reservedCount,
            salePrice: item.salePrice,
            shippedCount: item.shippedCount,
            sortedCount: item.sortedCount,
            storeId: item.storeId,
            storeName: item.storeName,
            supplier: item.supplier,
            taxCode: item.taxCode,
            tracking: item.tracking,
            warehouseId: item.warehouseId,
            itemBoxMap: item.itemBoxMap,
            amzRank: item.amzRank,
            smallImage: item.smallImage,
            largeImage: item.largeImage,
            productType: item.productType,
            palletId: item.palletId,
            itemQuantity: 0,
            boxCount: 0,
          }
          this.dataSource.push(batchItem)
        }
      })
      if (this.dataSource.length > 0) {
        const popupIns = this.$refs.itemPopupRef.instance
        popupIns.show()
      }
    },
    onShownItemPopup() {
      this.itemPopupTitle = 'Bulk Boxing'
    },
    onHiddenPopup() {
      this.dataSource.length = 0
      this.$emit('on-emit-bulk-box-closed-popup')
    },
    itemQuantityValidation(params) {
      const promise = new Promise((resolve, reject) => {
        const result = params.value * params.data.boxCount <= params.data.quantity
        resolve(result)
      })
      promise.then(
        result => result,
        error => error,
      )
      return promise
    },
    boxCountValidation(params) {
      const promise = new Promise((resolve, reject) => {
        const result = params.value * params.data.itemQuantity <= params.data.quantity
        resolve(result)
      })
      promise.then(
        result => result,
        error => error,
      )
      return promise
    },
    onSaveBox() {
      const dataGrid = this.$refs.shippingItemsContentRef.instance
      const items = dataGrid.getDataSource().items()
      const list = []
      let hasError = false
      items.forEach(item => {
        if (item.boxCount > 0 && item.itemQuantity > 0) {
          const totalQuantiy = item.boxCount * item.itemQuantity
          if (totalQuantiy > item.quantity) {
            Notify.error(`${item.asin}: (Item Quantity * Box Count) cannot be greater than quantity`)
            hasError = true
            return
          }
          const batchItem = {
            parentItemId: item.id,
            itemBatchList: [],
          }
          const itemBatch = {
            shipmentBatchId: this.batchId,
            shipmentBatchName: this.shipmentBatchName,
            dest: this.shipmentBatchDestination,
            totalItemQuantity: item.quantity,
            boxItemQuantityList: [],
          }
          for (let index = 0; index < item.boxCount; index++) {
            itemBatch.boxItemQuantityList.push({
              boxId: null,
              boxName: '',
              itemQuantity: item.itemQuantity,
            })
          }
          batchItem.itemBatchList.push(itemBatch)
          list.push(batchItem)
        }
      })
      if (!hasError) {
        fbaShipmentService.assignBatchParentItemToBoxesMap(list).then(() => {
          const popupIns = this.$refs.itemPopupRef.instance
          popupIns.hide()
        })
      }
    },
  },
}
</script>

<style lang="scss">

</style>
